import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";

export default defineNuxtPlugin({
    name: "flare",
    setup(nuxtApp) {
        if (nuxtApp.$config.public.appEnv === "local") {
            return;
        }

        const { data: user } = storeToRefs(useUserStore());

        watchEffect(() => {
            flare.addContextGroup(
                "Merchant",
                user.value
                    ? {
                          name: user.value?.app.display_name,
                      }
                    : {
                          name: "No Appname found",
                      },
            );

            flare.addContextGroup(
                "User",
                user.value
                    ? {
                          id: user.value.id,
                          email: user.value.email,
                          name: user.value.name,
                      }
                    : {
                          name: "Not logged in",
                      },
            );
        });

        flare.light(nuxtApp.$config.public.flareAppKey);

        flare.beforeSubmit = (report) => {
            return {
                ...report,
                application_path: report.context.request?.url,
            };
        };

        nuxtApp.vueApp.use(flareVue);

        nuxtApp.hook("vue:error", (error, _instance, info) => {
            const flareReportError = error as Error;

            flare.addContextGroup("Info", {
                title: flareReportError.message,
                additional_Info: info,
            });
            flare.report(flareReportError);
        });
    },
});
