import { SmakeUiPlugin } from "@smakecloud/smake-ui";
import { config } from "@fortawesome/fontawesome-svg-core";
import { icons } from "~/utils/icons";
// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(SmakeUiPlugin, {
        icons: icons,
    });
});
