/**
 * Ensures user is authenticated
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const { isLoggedIn } = storeToRefs(useTokenStore());
    const { slug } = storeToRefs(useLocalSettingsStore());

    if (!slug.value) {
        return showError({
            statusCode: 404,
        });
    }

    if (isGuestPage(to)) {
        return;
    }

    if (!isLoggedIn.value) {
        return navigateTo({
            name: "slug-login",
            params: {
                slug: slug.value,
            },
            ...(!is404Page(to) && { query: { redirect: to.fullPath } }),
        });
    }
});
