import {
    faBell,
    faCopyright,
    faShuffle,
    faUser,
    type IconDefinition,
} from "@fortawesome/pro-duotone-svg-icons";

export const duotoneIcons: IconDefinition[] = [
    faBell,
    faCopyright,
    faUser,
    faShuffle,
];
