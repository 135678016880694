import type { NuxtApp } from "#app";
import { storeToRefs } from "pinia";

export default defineNuxtPlugin({
    name: "i18n",
    setup(nuxtApp) {
        const { locale } = storeToRefs(useLocalSettingsStore());
        const i18n = nuxtApp.$i18n as NuxtApp["$i18n"];

        watchEffect(() => {
            void i18n.setLocale(locale.value);
        });
    },
});
