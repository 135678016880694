import { verify } from "@/lib/api/verify";

export const useUserStore = defineStore("userStore", () => {
    const { token } = storeToRefs(useTokenStore());
    const { slug } = storeToRefs(useLocalSettingsStore());

    const query = useQuery({
        queryKey: ["smake-use.merchantApi.app.verify", slug],
        queryFn: verify,
        select: (data) => data.user,
        retry: false,
        enabled: computed(() => !!token.value),
    });

    return query;
});
