import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { duotoneIcons } from "./duotone";
import { lightIcons } from "./light";
import { solidIcons } from "./solid";
import { regularIcons } from "./regular";

export const icons: IconDefinition[] = [
    ...duotoneIcons,
    ...lightIcons,
    ...solidIcons,
    ...regularIcons,
];
