import { useConfirm } from "@smakecloud/smake-ui";
/**
 * Handles everything related to the slug
 */

export default defineNuxtRouteMiddleware(async (to) => {
    const nuxtApp = useNuxtApp();
    const { t } = nuxtApp.$i18n;
    const { slug } = storeToRefs(useLocalSettingsStore());
    const { showDialog } = useConfirm();
    const { reveal, onReveal, confirm, cancel } = useConfirmDialog();
    const { isLoggedIn } = storeToRefs(useTokenStore());
    const targetSlug = ref<string>();

    onReveal(() => {
        showDialog(
            {
                id: "deleteLogo",
                title: t("change_merchant_title"),
                icon: "person-to-door",
                color: "danger",
                content: t("change_merchant_description", {
                    current_merchant: `<span class="font-bold">${slug.value}</span>`,
                    new_merchant: `<span class="font-bold">${targetSlug.value}</span>`,
                }),
                slots: {
                    confirm: () =>
                        t("continue_to", { target: targetSlug.value }),
                    cancel: () => t("helpers.buttons.back"),
                },
            },
            {
                confirmFn: confirm,
                cancelFn: cancel,
            },
        );
    });

    const pathArray = to.fullPath.toString().split("/");
    targetSlug.value = getSlug(pathArray);

    if (!isLoggedIn.value) {
        slug.value = targetSlug.value;
        return;
    }

    if (targetSlug.value === slug.value) {
        return;
    }

    if (!targetSlug.value && slug.value) {
        return await navigateTo({
            name: "slug-dashboard",
            params: {
                slug: slug.value,
            },
        });
    }

    const { isCanceled } = await reveal();

    abortNavigation();

    if (isCanceled) {
        return;
    }

    slug.value = targetSlug.value;
});

function getSlug(pathArray: string[]): string | undefined {
    if (pathArray[1] === "") {
        return undefined;
    }
    return pathArray[1];
}
