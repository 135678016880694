import { default as _91id_93E1bcwsUF7CMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexRiFwRHalgqMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_931Nc6YdUe2UMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexeFYhmCslYvMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsKyHq0tewlmMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variants3kmEIUa9KzMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexJ64yD49zaMMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexX5aFHW5F5hMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboarda6GpGaNTblMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/dashboard.vue?macro=true";
import { default as entrancemiL6kQRTn7Meta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/entrance.vue?macro=true";
import { default as indexs1eX05oAx8Meta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/index.vue?macro=true";
import { default as loginTfceOY4vyUMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/login.vue?macro=true";
import { default as index6dJ2JMRMcUMeta } from "/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93E1bcwsUF7CMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRiFwRHalgqMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexRiFwRHalgqMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_931Nc6YdUe2UMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexeFYhmCslYvMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsKyHq0tewlmMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variants3kmEIUa9KzMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexJ64yD49zaMMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexX5aFHW5F5hMeta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: indexX5aFHW5F5hMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboarda6GpGaNTblMeta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboarda6GpGaNTblMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrancemiL6kQRTn7Meta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: loginTfceOY4vyUMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: loginTfceOY4vyUMeta || {},
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.strauss.smake.eu/releases/20240917130922/pages/index.vue").then(m => m.default || m)
  }
]