<template>
    <nuxt-layout name="default">
        <div class="flex h-[calc(100vh-100px)] flex-col justify-center">
            <div class="m-auto">
                <smu-card class="m-auto flex w-full shadow-lg" with-border>
                    <div class="flex">
                        <div>
                            <h2
                                class="-mt-0.5 text-2xl text-primary sm:text-md"
                            >
                                {{ error.statusCode }}
                            </h2>
                        </div>
                        <div class="mx-5">
                            <div class="h-full w-[2px] bg-primary" />
                        </div>
                        <div
                            class="max-w-[200px] break-words text-neutral-500 sm:max-w-md md:max-w-lg lg:max-w-xl"
                        >
                            <h1 class="text-xl font-light">
                                <span v-if="error.statusMessage">
                                    {{ error.statusMessage }}
                                </span>
                                <span v-else>
                                    {{
                                        $t(
                                            `helpers.errors.${error.statusCode}.title`,
                                        )
                                    }}
                                </span>
                            </h1>
                            <!-- eslint-disable vue/no-v-html -->
                            <p class="pt-2" v-html="errorMessage" />
                        </div>
                    </div>
                </smu-card>
                <div class="mt-4 flex justify-end">
                    <smu-button type="text" @click="router.back()">
                        {{ t("helpers.buttons.back") }}
                    </smu-button>
                </div>
            </div>
        </div>
    </nuxt-layout>
</template>

<script setup lang="ts">
import { SmuCard } from "@smakecloud/smake-ui";
import DOMPurify from "dompurify";
import { type NuxtError } from "nuxt/app";

const { t } = useI18n();

const props = defineProps<{
    error: NuxtError;
}>();

const router = useRouter();

const errorMessage = computed(() =>
    DOMPurify.sanitize(
        t(
            props.error.statusCode === 404
                ? `helpers.errors.${props.error.statusCode}.message_without_url`
                : `helpers.errors.${props.error.statusCode}.message`,
        ),
    ),
);
</script>
