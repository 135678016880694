/**
 * @todo remove in {@link https://smake.atlassian.net/browse/STR-3033 | STR-3033}
 * @deprecated not required with typed routing, use typed routing instead https://nuxt.com/docs/guide/going-further/experimental-features#typedpages
 */
export const NuxtPageNames = {
    DASHBOARD: "slug-dashboard",
    INDEX: "index",
    LOGIN: "slug-login",
    ENTRANCE: "slug-entrance",
    LOGOS: "slug-catalog-logos",
    LOGOS_ID: "catalog-logos-id",
    CUSTOMERS_LOGOS: "slug-customers-customer-logos",
    CUSTOMERS_LOGOS_ID: "slug-customers-customer-logos-id",
    CUSTOMERS_ID_COLORS: "slug-customers-id-colors",
    CUSTOMERS: "customers",
    CUSTOMERS_ID: "slug-customers-id",
} as const;

export const GuestPages = [
    NuxtPageNames.ENTRANCE,
    NuxtPageNames.LOGIN,
] as string[];
