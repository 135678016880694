/**
 * Authorize user
 */
export default defineNuxtRouteMiddleware((to) => {
    const { isLoggedIn } = storeToRefs(useTokenStore());
    const { slug } = storeToRefs(useLocalSettingsStore());

    if (!slug.value) {
        return showError({
            statusCode: 404,
        });
    }

    const { isAuthorised } = useAuthorisationChecker();
    const isAuthorisedForPage = isAuthorised(to.meta);

    if (!isAuthorisedForPage) {
        return showError({
            statusCode: 404,
        });
    }

    if (isLoggedIn.value && to.name === "slug-login") {
        return navigateTo({
            name: "slug-dashboard",
            params: {
                slug: slug.value,
            },
        });
    }
});
